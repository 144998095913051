import { NgTemplateOutlet } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core'
import { RouterLink } from '@angular/router'

import { Icon } from '~icons'

import { BoxComponent } from '../../ui/box/box.component'
import { ButtonComponent } from '../../ui/button/button.component'
import { TextComponent } from '../../ui/text/text.component'

@Component({
  selector: 'sb-view-header',
  template: ` <div class="flex flex-col justify-between gap-3 md:flex-row md:items-center">
      <div>
        <ng-container [ngTemplateOutlet]="titleTemplateRef || (title && defaultTitleTemplate)" />
        <ng-container [ngTemplateOutlet]="subtitleTemplateRef || (subtitle && defaultSubtitleTemplate)" />
      </div>

      <sb-box direction="row" items="center" gap="1rem">
        <ng-container [ngTemplateOutlet]="actionsTemplateRef" />
      </sb-box>
    </div>

    <!-- Title template -->
    <ng-template #defaultTitleTemplate>
      <div class="flex items-center gap-2">
        @if (backLink) {
          <sb-button variant="icon" [icon]="Icon.ArrowBackIos" [routerLink]="backLink" />
        }
        <sb-text variant="title" noMargin>{{ title }}</sb-text>
      </div>
    </ng-template>

    <!-- Subtitle template -->
    <ng-template #defaultSubtitleTemplate>
      <sb-text variant="labelLarge" noMargin muted>{{ subtitle }}</sb-text>
    </ng-template>`,
  styles: [
    `
      @use 'variables' as var;

      sb-view-header {
        display: block;
        padding-top: var.$view-header-padding-top;
      }

      sb-view main > .toolbar {
        > div > sb-view-header:last-child,
        > sb-view-header:last-child {
          padding-bottom: var.$view-header-margin-bottom;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgTemplateOutlet, BoxComponent, ButtonComponent, RouterLink, TextComponent],
})
export class ViewHeaderComponent {
  Icon = Icon
  @ContentChild('actions') actionsTemplateRef: TemplateRef<unknown>
  @Input() @HostBinding('style.padding') pad: string
  @Input() subtitle?: string
  @ContentChild('subtitle') subtitleTemplateRef: TemplateRef<unknown>
  @Input() title?: string
  @ContentChild('title') titleTemplateRef: TemplateRef<unknown>
  @Input() backLink?: string[]
}
