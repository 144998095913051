import { inject } from '@angular/core'
import { type CanActivateFn, Router } from '@angular/router'
import { map } from 'rxjs/operators'

import { UserService } from '~core/services'

export const appOnboardingGuard: CanActivateFn = () => {
  const router = inject(Router)
  const userService = inject(UserService)

  return userService.lastStoredUser().pipe(
    map((user) => {
      if (user.onboarding.firstTime) {
        return router.createUrlTree(['/projects'])
      }

      return true
    }),
  )
}
