import { inject } from '@angular/core'
import { CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { UserService } from '~core/services'

const checkAuth = (state: RouterStateSnapshot): Observable<UrlTree | boolean> => {
  const userService = inject(UserService)
  const router = inject(Router)
  return userService.isAuthenticated.pipe(
    take(1),
    map((isAuth) => {
      if (!isAuth) {
        return router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } })
      }
      return true
    }),
  )
}

export const authenticationGuard: CanActivateFn | CanActivateChildFn = (_, state: RouterStateSnapshot) => {
  return checkAuth(state)
}
