import { inject } from '@angular/core'
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router'
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { UserService } from '~core/services'

export const downgradeGuard: CanActivateFn | CanActivateChildFn = () => {
  const router = inject(Router)

  const userService = inject(UserService)

  return userService.isDowngrading().pipe(
    map((isDowngrading) => {
      if (isDowngrading) {
        return router.createUrlTree(['user/subscription/downgrade'])
      }
      // The user is not downgrading
      return true
    }),
    catchError((error) => {
      if (error.status === 503) {
        return router.navigate(['maintenance'])
      }
      return throwError(() => error)
    }),
  )
}
