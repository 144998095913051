import { PortalModule } from '@angular/cdk/portal'
import { AsyncPipe, NgStyle, NgTemplateOutlet } from '@angular/common'
import {
  Component,
  ContentChild,
  ViewEncapsulation,
  input,
  Input,
  OnInit,
  TemplateRef,
  inject,
  ViewChild,
  AfterViewInit,
  ElementRef,
  OnDestroy,
} from '@angular/core'
import { MatDrawerContainer, MatSidenavModule } from '@angular/material/sidenav'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { delay, distinctUntilChanged } from 'rxjs/operators'
import { CustomStyle } from 'src/app/common/types'

import { DrawerService } from '~core/services/ui/drawer.service'
import { selectIsLoading } from '~core/store/core.selectors'
import { selectOpenDrawer } from '~core/store/ui/ui.selectors'

import { ViewHeaderComponent } from '../view-header/view-header.component'

export type ViewStyle = {
  toolbar?: CustomStyle
  content?: CustomStyle
  rightSidebar?: CustomStyle
}

@Component({
  selector: 'sb-view',
  template: `
    <div class="view-wrapper">
      <mat-drawer-container class="view-drawer-container">
        <mat-drawer #drawer mode="over" position="end" disableClose [opened]="drawerOpened$ | async">
          <ng-container
            [cdkPortalHost]="drawerService.drawerPortal()"
            (attached)="drawerService.initComponent($event)"
          />
        </mat-drawer>
        <mat-drawer-content>
          <main>
            <!--  <sb-loader-->
            <!--    *ngIf="isLoading$ | async"-->
            <!--    [customStyle]="{ borderRadius: '12px', width: 'calc(100% - 8px)' }"-->
            <!--    absolute-->
            <!--    overlay-->
            <!--  ></sb-loader>-->
            <div class="toolbar" [ngStyle]="toolbarStyle">
              @if (toolbarTemplateRef) {
                <ng-container [ngTemplateOutlet]="toolbarTemplateRef" />
              } @else if (title()) {
                <sb-view-header [title]="title()" [subtitle]="subtitle()" />
              }
            </div>
            <div class="content" [ngStyle]="contentStyle">
              <div class="scrollable-container">
                <ng-container [ngTemplateOutlet]="contentTemplateRef" />
                <ng-content></ng-content>
              </div>
            </div>
            <aside class="right-sidebar" [ngStyle]="rightSidebarStyle">
              <ng-container [ngTemplateOutlet]="rightSidebarTemplateRef" />
            </aside>
          </main>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  `,
  styles: `
    @use 'sass:map';
    @use 'themes/config/palette';
    @use 'variables' as var;
    @use 'mixins';

    $view-radius: var.$base-radius * 3;

    sb-view > .view-wrapper {
      --mat-sidenav-scrim-color: #{transparentize(map.get(palette.$neutral, 780), 0.33)};
      height: 100%;
      background: transparent;
      border-radius: 0;
      padding: 0;

      @include mixins.media-breakpoint-up(md) {
        border-radius: $view-radius;
        padding-bottom: var.$main-page-padding;
        padding-right: var.$main-page-padding;
      }

      mat-drawer-container.mat-drawer-container.view-drawer-container {
        height: 100%;
        border-radius: 0;
        background: transparent;

        @include mixins.media-breakpoint-up(md) {
          border-radius: $view-radius;
        }

        mat-drawer.mat-drawer.mat-drawer-end {
          border-radius: 0;
          max-width: 80%;

          @include mixins.media-breakpoint-up(md) {
            border-radius: $view-radius;
          }
        }
      }

      main {
        height: 100%;
        overflow: hidden;
        display: grid;
        grid-template-areas:
          'toolbar right-sidebar'
          'content right-sidebar';
        grid-template-columns: 1fr auto;
        grid-template-rows: auto 1fr;
        padding-bottom: 0;

        @include mixins.media-breakpoint-up(md) {
          /* padding-bottom: var.$main-page-padding; */
        }

        > .toolbar {
          grid-area: toolbar;
          border-radius: 0;
          max-width: 100%;
          overflow: hidden;
          padding: var.$view-toolbar-padding-y var.$view-toolbar-padding-x;

          @include mixins.media-breakpoint-up(md) {
            border-radius: $view-radius $view-radius 0 0;
            padding: var.$view-content-padding-y var.$view-content-padding-x-md;
          }
        }

        > .content {
          grid-area: content;
          border-radius: 0;
          overflow: hidden;
          position: relative;
          padding: var.$view-content-padding-y 0 var.$view-content-padding-y var.$view-content-padding-x;

          @include mixins.media-breakpoint-up(md) {
            border-radius: 0 0 $view-radius $view-radius;
            padding: var.$view-content-padding-y 0 var.$view-content-padding-y var.$view-content-padding-x-md;
          }

          > .scrollable-container {
            height: 100%;
            overflow-y: scroll;
            padding-right: var.$view-content-padding-x;

            @include mixins.media-breakpoint-up(md) {
              padding-right: var.$view-content-padding-x-md;
              /* padding-bottom: 3rem; */
            }
          }
        }

        > .right-sidebar {
          grid-area: right-sidebar;
          min-width: 0;

          @include mixins.media-breakpoint-up(md) {
            /* min-width: var.$main-page-padding; */
            margin-right: -(var.$main-page-padding);
          }
        }
      }
    }
  `,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [NgStyle, NgTemplateOutlet, MatSidenavModule, AsyncPipe, PortalModule, ViewHeaderComponent],
})
export class ViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatDrawerContainer, { static: true, read: ElementRef }) drawerContainer: ElementRef<HTMLElement>
  @ContentChild('content') contentTemplateRef: TemplateRef<unknown>
  @Input() customStyle?: ViewStyle
  isLoading$: Observable<boolean>
  drawerOpened$: Observable<boolean>
  @ContentChild('rightSidebar') rightSidebarTemplateRef: TemplateRef<unknown>
  title = input<string>()
  subtitle = input<string>()
  @ContentChild('toolbar') toolbarTemplateRef: TemplateRef<unknown>
  console = console
  #store = inject(Store)
  drawerService = inject(DrawerService)

  get contentStyle(): CustomStyle {
    return this.toolbarTemplateRef || this.title() || this.subtitle()
      ? this.customStyle?.content
      : { borderRadius: '12px', ...this.customStyle?.content }
  }

  get rightSidebarStyle(): CustomStyle {
    return this.customStyle?.rightSidebar
  }

  get toolbarStyle(): CustomStyle {
    return this.customStyle?.toolbar
  }

  ngOnInit(): void {
    this.isLoading$ = this.#store.select(selectIsLoading).pipe(delay(0), distinctUntilChanged())
    this.drawerOpened$ = this.#store.select(selectOpenDrawer)
  }

  ngAfterViewInit(): void {
    this.drawerContainer.nativeElement.querySelector('.mat-drawer-backdrop').addEventListener('click', this.closeDrawer)
  }

  ngOnDestroy(): void {
    this.drawerContainer.nativeElement
      .querySelector('.mat-drawer-backdrop')
      .removeEventListener('click', this.closeDrawer)
    this.drawerService.clearDrawerContent()
    this.closeDrawer()
  }

  closeDrawer = () => {
    this.drawerService.closeDrawer()
  }
}
