import { inject } from '@angular/core'
import { CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router'
import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { UserService } from '~core/services'
import { SnackbarService } from '~core/services/ui/snackbar.service'

const checkCapability = (capabilities: string[], all = true) => {
  const userService = inject(UserService)
  const snackbarService = inject(SnackbarService)
  const router = inject(Router)
  return userService.hasCapability(capabilities, all).pipe(
    map((hasCapability: boolean) => {
      if (!hasCapability) {
        setTimeout(() => snackbarService.error("Hey man, I'm watching you!"))
        return router.createUrlTree(['/'])
      }

      // The user has capability
      return true
    }),
    catchError((error) => {
      if (error.status === 503) {
        return router.navigate(['/maintenance'])
      }
      return throwError(error)
    }),
  )
}

const checkProjectRoute = (route) => {
  const patterns = {
    videos: new RegExp(/\/projects\/.+\/facebook-page\/.*\/content\/videos/),
    stories: new RegExp(/\/projects\/.+\/instagram-account\/.*\/content\/stories/),
    segments: new RegExp(/\/projects\/.+\/(facebook-page|instagram-account)\/.*\/content\/segments(\/.*)*/),
    goals: new RegExp(/\/projects\/.+\/(facebook-page|instagram-account)\/.*\/goals(\/.*)*/),
    ads: new RegExp(/\/projects\/.+\/facebook-ad-account\/.*\/content\/ads/),
    reports: new RegExp(/\/projects\/.+\/reports(\/.*)*/),
    competitors: new RegExp(/\/projects\/.+\/(competitors|competitors-settings)(\/.*)*/),
  }

  if (patterns.videos.test(route)) {
    return checkCapability(['content', 'fb-videos-feed'])
  }

  if (patterns.stories.test(route)) {
    return checkCapability(['content', 'ig-stories-feed'])
  }

  if (patterns.ads.test(route)) {
    return checkCapability(['facebook-ads', 'content'])
  }

  if (patterns.segments.test(route)) {
    return checkCapability(['segments'])
  }

  if (patterns.goals.test(route)) {
    return checkCapability(['goals'])
  }

  if (patterns.reports.test(route)) {
    return checkCapability(['reports'])
  }

  if (patterns.competitors.test(route)) {
    return checkCapability(['competitors'])
  }

  return true
}

const canAccessRoute = (route: string) => {
  if (route.startsWith('/admin')) {
    return checkCapability(['sudo'])
  }

  if (route.startsWith('/projects')) {
    return checkProjectRoute(route)
  }

  return true
}

export const authorizationGuard: CanActivateFn | CanActivateChildFn = (_, state: RouterStateSnapshot) => {
  return canAccessRoute(state.url)
}
